<template>
  <div class="page">
    <div class="title">企业基础信息</div>

    <el-divider></el-divider>

    <el-form
      ref="form"
      :rules="rules"
      label-position="top"
      :model="form"
      label-width="80px"
    >
      <el-form-item label="公司logo" prop="image">
        <el-image
          v-if="form.image"
          :src="host + form.image"
          style="width: 100px; height: 100px"
          fit="cover"
          @click="chooseImage"
          @error="form.image = ''"
        ></el-image>
        <div v-else class="add_photo" @click="chooseImage">
          <i class="el-icon-plus"></i>
        </div>
        <input
          ref="fileInput"
          type="file"
          accept="image/*"
          @change="uploadImage"
          @click="
            (event) => {
              event.target.value = null;
            }
          "
          style="width: 0; height: 0; position: absolute"
        />
      </el-form-item>

      <el-divider></el-divider>

      <el-form-item label="企业名称" prop="name">
        <el-input v-model="form.name"></el-input>
      </el-form-item>

      <el-form-item label="社会统一信用代码" prop="social_code">
        <el-input v-model="form.social_code"></el-input>
      </el-form-item>

      <el-form-item label="企业行业" prop="industry">
        <el-input v-model="form.industry"></el-input>
      </el-form-item>

      <el-form-item label="企业所在地区" prop="dataModel">
        <el-cascader
          v-if="areaData"
          v-model="form.dataModel"
          placeholder="请选择地区"
          :options="areaData"
        >
        </el-cascader>
      </el-form-item>

      <el-form-item label="详细地址" prop="address">
        <el-input v-model="form.address"></el-input>
      </el-form-item>

      <el-form-item label="企业规模" prop="staff_num">
        <el-input v-model="form.staff_num"></el-input>
      </el-form-item>

      <el-divider></el-divider>

      <el-form-item label="企业联系人" prop="contact_people">
        <el-input v-model="form.contact_people"></el-input>
      </el-form-item>

      <el-form-item label="联系电话" prop="contact_mobile">
        <el-input v-model="form.contact_mobile"></el-input>
      </el-form-item>

      <el-form-item label="企业邮编" prop="post_code">
        <el-input v-model="form.post_code"></el-input>
      </el-form-item>

      <el-form-item class="form_btns">
        <div class="form_btns">
          <div class="custom_button plain no_select" @click="onSubmit">
            提交认证
          </div>
          <div class="custom_button no_select" @click="onReset">重置</div>
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import AreaJson from "@/util/area-full.json";

export default {
  data() {
    return {
      host: process.env.VUE_APP_BASE_API,
      areaData: null,
      form: {
        dataModel: "",
        image: "",
        name: "",
        social_code: "",
        industry: "",
        location: "",
        address: "",
        staff_num: "",
        contact_people: "",
        contact_mobile: "",
        province: "",
        city: "",
        area: "",
        post_code: "",
      },
      rules: {
        image: [
          {
            required: true,
            message: "请上传公司logo",
            trigger: "blur",
          },
        ],
        name: [
          {
            required: true,
            message: "请填写企业名称",
            trigger: "blur",
          },
        ],
        social_code: [
          {
            required: true,
            message: "请填写社会统一信用代码",
            trigger: "blur",
          },
          {
            pattern: /^[0-9A-HJ-NPQRTUWXY]{2}\d{6}[0-9A-HJ-NPQRTUWXY]{10}$/,
            message: "请填写正确的社会统一信用代码",
            trigger: "blur",
          },
        ],
        industry: [
          {
            required: true,
            message: "请填写企业行业",
            trigger: "blur",
          },
        ],
        dataModel: [
          {
            required: true,
            message: "请填写企业所在地区",
            trigger: "change",
          },
        ],
        address: [
          {
            required: true,
            message: "请填写详细地址",
            trigger: "blur",
          },
        ],
        staff_num: [
          {
            required: true,
            message: "请填写企业规模",
            trigger: "blur",
          },
        ],
        contact_people: [
          {
            required: true,
            message: "请填写企业联系人",
            trigger: "blur",
          },
        ],
        contact_mobile: [
          {
            required: true,
            message: "请填写联系电话",
            trigger: "blur",
          },
        ],
        post_code: [
          {
            required: true,
            message: "请填写企业邮编",
            trigger: "blur",
          },
        ],
      },
    };
  },
  async created() {
    this.areaData = AreaJson;
    for (var i = 0; i < this.areaData.length; i++) {
      if (this.areaData[i].children.length == 0) {
        delete this.areaData[i].children; //解决因为省级区域没有下级市的BUG
      }
    }

    await this.getEnterpriseInfo()
      .then((res) => {
        console.log(res);
        this.form = res.data.data.info;
        let province = this.areaData.find(
          (item) => item.label == this.form.province
        );
        let city = province.children.find(
          (item) => item.label == this.form.city
        );
        let area = city.children.find((item) => item.label == this.form.area);
        this.form.dataModel = [province.value, city.value, area.value];
      })
      .catch((e) => {
        console.log(e);
      });
  },
  methods: {
    ...mapActions(["uploadFile"]),
    ...mapActions("enterprise", ["getEnterpriseInfo", "postEnterpriseInfo"]),

    /**
     * 选择图片
     */
    chooseImage() {
      this.$refs.fileInput.click();
    },

    /**
     * 上传图片
     */
    uploadImage(file) {
      let loading = this.$loading();
      console.log(file);
      this.uploadFile(file.target.files[0])
        .then((res) => {
          loading.close();
          console.log(res);
          this.form.image = res.data.data.full_link;
        })
        .catch((err) => {
          loading.close();
          console.error(err);
          this.$message.error(err.msg);
        });
    },

    /**
     * 提交表单
     */
    onSubmit() {
      let province = this.areaData.find(
        (item) => item.value == this.form.dataModel[0]
      );
      let city = province.children.find(
        (item) => item.value == this.form.dataModel[1]
      );
      let area = city.children.find(
        (item) => item.value == this.form.dataModel[2]
      );
      this.form.province = province.label;
      this.form.city = city.label;
      this.form.area = area.label;
      this.$refs.form.validate((valid) => {
        if (valid) {
          let loading = this.$loading();
          console.log(this.form);
          this.postEnterpriseInfo(this.form)
            .then((res) => {
              console.log(res);
              loading.close();
              this.$message.success("信息提交成功");
            })
            .catch((e) => {
              console.error(e);
              loading.close();
              this.$message.error("信息提交失败");
            });
        } else {
          this.$notify.error({
            title: "提交失败",
            message: "请先完整填写表单",
          });
          return false;
        }
      });
    },

    onReset() {
      this.$confirm("重置表单, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$refs.form.resetFields();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  font-size: 14px;
  font-weight: bold;
  color: #1c2438;
}

.add_photo {
  width: 100px;
  height: 100px;
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #dcdfe6;
  border-radius: 3px;
  color: #dcdfe6;
}

::v-deep .el-form-item__label {
  font-size: 14px;
  color: #222222;
}

::v-deep .el-input__inner {
  width: 480px;
}

.el-input {
  width: 400px;
  height: 48px;
  font-size: 14px;
  color: #222222;
}

.form_btns {
  display: flex;
  align-items: center;

  .custom_button {
    width: 142px;
    height: 48px;
    line-height: 48px;
    font-size: 14px;
  }

  > div:not(:first-child) {
    margin-left: 20px;
  }
}
</style>
